<template>
    <v-card>
        <v-card-title class="text-h3 mb-2">Authentification à double facteurs</v-card-title>
        <v-card-subtitle>Sécurisez votre compte avec une authentication supplémentaire lors de la connexion à un nouvel appareil.</v-card-subtitle>
        <v-card-text>
            <v-btn block color="primary" class="mb-4">Activer l'authentication à double facteurs</v-btn>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name : 'A2F',
}
</script>